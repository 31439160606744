import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  VERSION_SERVER,
  TINYMC_API_KEY,
} from "components/utils/constants/misc";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Autocomplete from "@mui/material/Autocomplete";
import { Editor } from "@tinymce/tinymce-react";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useState } from "react";
import { TagsInput } from "react-tag-input-component";
import MergeTagsInfo from "views/components/MergeTagsInfo";
import "./EditTemplateFormikWrapper.css";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const EditTemplateFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    objTemplate,
    documentSectionsToSave,
    editorDocument,
    setEditorDocument,
    handleUpdateDocumentSectionsToSave,
    handleAutoSave,
    savingSectionIndex,
    btnClickSectionSave,
    templateStatus,
    editorRef,
    readOnly,
    mergeTags,
  } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [editorsToShow, setEditorsToShow] = useState([]);

  const showEditor = (section_id) => {
    let updatedEditorsToShow = [...editorsToShow];
    const index = updatedEditorsToShow.indexOf(section_id);
    if (index !== -1) {
      updatedEditorsToShow.splice(index, 1);
    } else {
      updatedEditorsToShow.push(section_id);
    }
    setEditorsToShow(updatedEditorsToShow);
  };

  function handleChange(frameworkId) {
    let newValues = values.risk_management_frameworks.map((framework) => {
      return framework.id === frameworkId
        ? { ...framework, selected: !framework.selected }
        : framework;
    });
    setFieldValue("risk_management_frameworks", newValues);
  }

  const mergeTagsList = [
    {
      title: "Global",
      menu:
        mergeTags?.global?.map((tag) => ({
          value: tag.name,
          title: tag.description,
        })) ?? [],
    },
  ];

  if (mergeTags?.company?.length > 0) {
    mergeTagsList.push({
      title: "Company",
      menu:
        mergeTags?.company?.map((tag) => ({
          value: tag.name,
          title: tag.description ?? tag.name,
        })) ?? [],
    });
  }

  return (
    <MDBox my={3} textAlign="left">
      <MDBox px={5} textAlign="left" mx="auto" mt={4}>
        <Grid
          container
          justifyContent="space-between"
          columnSpacing={2}
          rowSpacing={3}
        >
          <Grid item xs={12} md={6} pt={0}>
            <MDBox mb={0}>
              <MDBox mb={0}>
                {templateStatus ? (
                  <>
                    {templateStatus == "Published" ? (
                      <Badge
                        badgeContent={"Published"}
                        color="success"
                        sx={{ marginLeft: "2rem", marginBottom: "1.5rem" }}
                      ></Badge>
                    ) : (
                      <Badge
                        badgeContent={"Draft"}
                        color="primary"
                        sx={{ marginLeft: "1.2rem", marginBottom: "1.5rem" }}
                      ></Badge>
                    )}
                  </>
                ) : (
                  ""
                )}

                <MDTypography variant="h5" fontWeight="bold">
                  Edit template
                </MDTypography>
              </MDBox>
              <MDTypography variant="body2" color="text">
                Template details
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={0} pt={2} px={5} pb={5}>
        <Grid container justifyContent="space-between" columnSpacing={5}>
          {/* Left column */}
          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <FormField
                type="text"
                label="Template Name"
                name="template_name"
                value={values.template_name}
                placeholder={""}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} lg={12} mb={3} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <Autocomplete
                disableClearable
                value={values.template_type}
                options={["Lite", "Plus"]}
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Template Type"
                    fullWidth
                  />
                )}
                onChange={(event, value) => {
                  if (value === null) {
                    setFieldValue("template_type", "");
                  } else {
                    setFieldValue("template_type", value);
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* Right column */}
          {/* Left column */}
          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              {/* <MDBox> */}
              <label className="tags-label" htmlFor="templateTags">
                Template Tags
              </label>
              <TagsInput
                value={values.tags}
                onChange={(value) => {
                  if (value === null) {
                    setFieldValue("templateTags", []);
                  } else {
                    setFieldValue("templateTags", value);
                  }
                }}
                name="templateTags"
                placeHolder=""
                className="tags-input-container"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormLabel sx={{ fontSize: "0.75em" }}>
              Risk Management Frameworks (RMF)
            </FormLabel>

            {values.risk_management_frameworks.map((framework, i) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      checked={framework.selected == 1 ? true : false}
                      name={
                        framework.owning_rmf.name
                          ? framework.owning_rmf.name.replace(/\s/g, "")
                          : ""
                      }
                    />
                  }
                  label={
                    framework.owning_rmf.name ? framework.owning_rmf.name : ""
                  }
                  key={framework.id}
                  onChange={() => handleChange(framework.id)}
                />
              );
            })}
          </Grid>

          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <FormField
                pt={0}
                type="text"
                label="Short Description"
                name="description"
                value={values.description}
                placeholder={""}
                error={errors.description && touched.description}
                success={values.description > 0 && !errors.description}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <FormField
                pt={0}
                type="text"
                label="Notes"
                name="notes"
                value={values.notes}
                placeholder={""}
                error={errors.notes && touched.notes}
                success={values.notes > 0 && !errors.notes}
              />
            </Grid>
          </Grid>

          <Grid
            className="no-pt-element"
            container
            mt={3}
            item
            xs={12}
            columnSpacing={8}
            pt={0}
          >
            <Grid item xs={12}>
              {objTemplate.doc_type === "oscal" ? (
                <>
                  {documentSectionsToSave.map((section, index) => {
                    return (
                      <Grid
                        container
                        item
                        xs={12}
                        lg={12}
                        rowSpacing={3}
                        key={index}
                        mb={5}
                      >
                        <Grid item xs={12} pt={0}>
                          {/* Accordion start */}
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon color="icon" />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{
                                flexDirection: "row-reverse",
                                borderBottom: "1px solid #ababab",
                                background: darkMode ? "#2f3958" : "#ededed",
                              }}
                            >
                              <MDTypography
                                variant="h6"
                                // color="primaryBlue"
                                p={1}
                              >
                                {section.owning_global_section.title}
                              </MDTypography>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                backgroundColor: darkMode
                                  ? "#2f3958"
                                  : "#e5e9ec",
                              }}
                            >
                              {/* tab panels */}
                              <MDBox mt={3}>
                                {/* About section */}
                                <Accordion
                                  style={{
                                    marginBottom: "25px",
                                    marginTop: "16px",
                                    borderRadius: "5px",
                                    boxShadow: "none",
                                  }}
                                  sx={
                                    darkMode
                                      ? { backgroundColor: "#fff" }
                                      : { backgroundColor: "#fff" }
                                  }
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color="icon" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <MDTypography
                                      variant="h6"
                                      style={{
                                        fontSize: "15px",
                                        color: "grey",
                                      }}
                                    >
                                      Reference Material
                                    </MDTypography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {/* tab panels */}
                                    <MDBox mt={3}>
                                      <div
                                        style={{
                                          display: `${
                                            editorsToShow.includes(
                                              section.id + "-about"
                                            )
                                              ? "block"
                                              : "none"
                                          }`,
                                        }}
                                      >
                                        <Editor
                                          key={
                                            index +
                                            section.owning_global_section.id
                                          }
                                          apiKey={TINYMC_API_KEY}
                                          value={section.section_about}
                                          init={{
                                            branding: false,
                                            height: 300,
                                            menubar: false,
                                            advcode_inline: true,
                                            automatic_uploads: true,
                                            mergetags_list: mergeTagsList,
                                          }}
                                          onEditorChange={(
                                            newValue,
                                            editor
                                          ) => {
                                            handleUpdateDocumentSectionsToSave(
                                              "section_about",
                                              index,
                                              newValue
                                            );
                                          }}
                                          plugins={[
                                            "anchor",
                                            "autolink",
                                            "advcode",
                                            "link",
                                            "lists",
                                            "searchreplace",
                                            "table",
                                            "visualblocks",
                                            "wordcount",
                                            "checklist",
                                            "mediaembed",
                                            "casechange",
                                            "export",
                                            "formatpainter",
                                            "pageembed",
                                            "linkchecker",
                                            "a11ychecker",
                                            // "tinymcespellchecker",
                                            "permanentpen",
                                            "powerpaste",
                                            "advtable",
                                            "advcode",
                                            "editimage",
                                            "tableofcontents",
                                            "footnotes",
                                            "mergetags",
                                            "autocorrect",
                                            "fullscreen",
                                            "inlinecss",
                                          ]}
                                          toolbar={[
                                            "fullscreen undo redo | blocks fontfamily fontsize | " +
                                              "bold italic underline strikethrough | removeformat code",
                                            "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                                              "checklist numlist bullist indent outdent ",
                                          ]}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          maxHeight: "500px",
                                          overflow: "auto",
                                          padding: "5px",
                                          borderRadius: "5px",
                                          display: `${
                                            editorsToShow.includes(
                                              section.id + "-about"
                                            )
                                              ? "none"
                                              : "block"
                                          }`,
                                        }}
                                      >
                                        <div
                                          style={{
                                            color: darkMode ? "white" : "",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: `<div class='dangerousHTMLDIVClass'>${section.section_about}</div>`,
                                          }}
                                        />
                                      </div>
                                      {savingSectionIndex === index ? (
                                        <MDBox
                                          sx={{
                                            marginTop: "20px",
                                          }}
                                        >
                                          <LoadingSpinner
                                            color="success"
                                            size="lg"
                                          />
                                        </MDBox>
                                      ) : (
                                        <MDBox
                                          sx={{
                                            display: "flex",
                                            gap: 2,
                                          }}
                                        >
                                          <MDButton
                                            color="dark"
                                            onClick={() =>
                                              showEditor(section.id + "-about")
                                            }
                                            sx={{
                                              marginTop: "20px",
                                              display: `${
                                                editorsToShow.includes(
                                                  section.id + "-about"
                                                )
                                                  ? "none"
                                                  : "block"
                                              }`,
                                            }}
                                          >
                                            Edit Section Reference Material
                                          </MDButton>
                                          <MDButton
                                            color="dark"
                                            onClick={() =>
                                              btnClickSectionSave(index, () =>
                                                showEditor(
                                                  section.id + "-about"
                                                )
                                              )
                                            }
                                            sx={{
                                              marginTop: "20px",
                                              display: `${
                                                editorsToShow.includes(
                                                  section.id + "-about"
                                                )
                                                  ? "block"
                                                  : "none"
                                              }`,
                                            }}
                                          >
                                            Save Section Reference Material
                                          </MDButton>
                                        </MDBox>
                                      )}
                                    </MDBox>
                                  </AccordionDetails>
                                </Accordion>

                                {/* content editor */}
                                <div
                                  style={{
                                    display: `${
                                      editorsToShow.includes(
                                        section.id + "-content"
                                      )
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  {section.owning_global_section.title ===
                                    "Policy" && (
                                    <MergeTagsInfo mergeTags={mergeTags} />
                                  )}
                                  <Editor
                                    onInit={(evt, editor) => {
                                      if (
                                        section.owning_global_section.title ===
                                        "Policy"
                                      ) {
                                        editorRef.current = editor;
                                      }
                                    }}
                                    key={index + section.id}
                                    apiKey={TINYMC_API_KEY}
                                    value={section.section_content}
                                    init={{
                                      branding: false,
                                      height: 500,
                                      menubar: false,
                                      advcode_inline: true,
                                      automatic_uploads: true,
                                      mergetags_list: mergeTagsList,
                                    }}
                                    onEditorChange={(newValue, editor) => {
                                      handleUpdateDocumentSectionsToSave(
                                        "section_content",
                                        index,
                                        newValue
                                      );
                                    }}
                                    plugins={[
                                      "anchor",
                                      "autolink",
                                      "advcode",
                                      "link",
                                      "lists",
                                      "searchreplace",
                                      "table",
                                      "visualblocks",
                                      "wordcount",
                                      "checklist",
                                      "mediaembed",
                                      "casechange",
                                      "export",
                                      "formatpainter",
                                      "pageembed",
                                      "linkchecker",
                                      "a11ychecker",
                                      "tinymcespellchecker",
                                      "permanentpen",
                                      "powerpaste",
                                      "advtable",
                                      "advcode",
                                      "editimage",
                                      "tableofcontents",
                                      "footnotes",
                                      "mergetags",
                                      "autocorrect",
                                      "fullscreen",
                                      "inlinecss",
                                    ]}
                                    toolbar={[
                                      "fullscreen undo redo | blocks fontfamily fontsize | " +
                                        "bold italic underline strikethrough | removeformat code",
                                      "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                                        "checklist numlist bullist indent outdent ",
                                    ]}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    maxHeight: "500px",
                                    overflow: "auto",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    display: `${
                                      editorsToShow.includes(
                                        section.id + "-content"
                                      )
                                        ? "none"
                                        : "block"
                                    }`,
                                  }}
                                >
                                  <div
                                    style={{ color: darkMode ? "white" : "" }}
                                    dangerouslySetInnerHTML={{
                                      __html: `<div class='dangerousHTMLDIVClass'>${section.section_content}</div>`,
                                    }}
                                  />
                                </div>
                                {savingSectionIndex === index ? (
                                  <MDBox
                                    sx={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    <LoadingSpinner color="success" size="lg" />
                                  </MDBox>
                                ) : (
                                  <MDBox
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                    }}
                                  >
                                    <MDButton
                                      color="dark"
                                      onClick={() =>
                                        showEditor(section.id + "-content")
                                      }
                                      sx={{
                                        marginTop: "20px",
                                        display: `${
                                          editorsToShow.includes(
                                            section.id + "-content"
                                          )
                                            ? "none"
                                            : "block"
                                        }`,
                                      }}
                                    >
                                      Edit Section Content
                                    </MDButton>
                                    <MDButton
                                      color="dark"
                                      onClick={() =>
                                        btnClickSectionSave(index, () =>
                                          showEditor(section.id + "-content")
                                        )
                                      }
                                      sx={{
                                        marginTop: "20px",
                                        display: `${
                                          editorsToShow.includes(
                                            section.id + "-content"
                                          )
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      Save Section Content
                                    </MDButton>
                                  </MDBox>
                                )}
                              </MDBox>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  <MergeTagsInfo mergeTags={mergeTags} />
                  <Editor
                    disabled={readOnly}
                    apiKey={TINYMC_API_KEY}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    onEditorChange={(newValue, editor) => {
                      setEditorDocument(newValue);
                    }}
                    value={editorDocument}
                    init={{
                      branding: false,
                      height: 400,
                      menubar: false,
                      advcode_inline: true,
                      automatic_uploads: true,
                      images_upload_url:
                        process.env.REACT_APP_BACKEND_API_URL +
                        VERSION_SERVER +
                        "/upload-images-to-server",
                      external_plugins: {
                        polygonAutosave:
                          process.env.PUBLIC_URL +
                          "/autoSaveMCEPlugin/autoSaveMCEPlugin.js",
                        polygonVersionBrowser:
                          process.env.PUBLIC_URL +
                          "/versionBrowserPlugin/versionBrowserPlugin.js",
                      },
                      polygonAutosave_autosaveCall: handleAutoSave,
                      polygonAutosave_interval: "30s",
                      polygonVersionBrowser_versionsCallback:
                        props.documentVersions,
                      polygonVersionBrowser_fetchVersionCallback:
                        props.fetchDocumentVersion,
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      mergetags_list: mergeTagsList,
                    }}
                    plugins={[
                      "anchor",
                      "autolink",
                      "advcode",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "visualblocks",
                      "wordcount",
                      "checklist",
                      "mediaembed",
                      "casechange",
                      "export",
                      "formatpainter",
                      "pageembed",
                      "linkchecker",
                      "a11ychecker",
                      // "tinymcespellchecker",
                      "permanentpen",
                      "powerpaste",
                      "advtable",
                      "advcode",
                      "editimage",
                      "tableofcontents",
                      "footnotes",
                      "mergetags",
                      "autocorrect",
                      "fullscreen",
                      "inlinecss",
                    ]}
                    toolbar={[
                      "fullscreen undo redo | blocks fontfamily fontsize | " +
                        "bold italic underline strikethrough | removeformat code",
                      "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                        "checklist numlist bullist indent outdent ",
                    ]}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default EditTemplateFormikWrapper;
