import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const getTaskList = createAsyncThunk(
  "company/getTaskList",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createTaskList = createAsyncThunk(
  "company/createTaskList",
  async (values, thunkApi) => {
    try {
      const { objUser, objData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists`,
        { ...objData }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTaskListDetails = createAsyncThunk(
  "company/getTaskListDetails",
  async (values, thunkApi) => {
    try {
      const { objUser, task_list_id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/details/${task_list_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateTaskList = createAsyncThunk(
  "company/updateTaskList",
  async (values, thunkApi) => {
    try {
      const { objUser, objData } = values;
      const { id, ...updateData } = objData;
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${id}`,
        { ...updateData }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteTaskList = createAsyncThunk(
  "company/deleteTaskList",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;
      const taskListId = dataFromActionBtn.id;
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${taskListId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createTaskListItem = createAsyncThunk(
  "company/createTaskListItem",
  async (values, thunkApi) => {
    try {
      const { objUser, objData, taskListId } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${taskListId}/items`,
        objData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTaskListItemDetails = createAsyncThunk(
  "company/getTaskListItemDetails",
  async (values, thunkApi) => {
    try {
      const { objUser, task_list_id, task_list_item_id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${task_list_id}/items/${task_list_item_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTaskListItems = createAsyncThunk(
  "company/getTaskListItems",
  async (values, thunkApi) => {
    try {
      const { objUser, task_list_id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${task_list_id}/items`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateTaskListItem = createAsyncThunk(
  "company/updateTaskListItem",
  async (values, thunkApi) => {
    try {
      const { objUser, objData, taskListId } = values;
      const { id, ...updateData } = objData;
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${taskListId}/items/${id}`,
        { ...updateData }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteTaskListItem = createAsyncThunk(
  "company/deleteTaskListItem",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;
      const { task_list_id, id } = dataFromActionBtn;
      console.log("DeleteData", dataFromActionBtn);
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${task_list_id}/items/${id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const notifyUserAssignedToTaskListItem = createAsyncThunk(
  "company/notifyUserAssignedToTaskListItem",
  async (values, thunkApi) => {
    try {
      const { objUser, taskListId, taskListItemId } = values;
      console.log("Notification", values);
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/${taskListId}/items/${taskListItemId}/notify-responsible-user`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTaskListTemplates = createAsyncThunk(
  "company/getTaskListTemplates",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/templates`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const cloneTaskListTemplate = createAsyncThunk(
  "company/getTaskListTemplates",
  async (values, thunkApi) => {
    try {
      const { objUser, cloneTemplateData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${getCompanyID(
          objUser
        )}/task-lists/clone-task-list-template`,
        { ...cloneTemplateData }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
